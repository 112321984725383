import 'plyr/dist/plyr.css';
import 'prosemirror-view/style/prosemirror.css'
import './app.css';
import Plyr from 'plyr';
import {EditorState, Plugin, PluginKey, TextSelection, Selection} from 'prosemirror-state';
import {Schema, Node} from 'prosemirror-model';
import {Decoration, DecorationSet, EditorView} from 'prosemirror-view';
import {keymap} from 'prosemirror-keymap';
import {baseKeymap} from 'prosemirror-commands';
import {undo, redo, history} from 'prosemirror-history';
import {parseSync, stringifySync} from 'subtitle';
import onlyOneTab from 'only-one-tab';
import applyDevTools from "prosemirror-dev-tools";

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./service-worker.js');
}


const schema = new Schema({
    nodes: {
        doc: {
            content: 'videotext{1}',
        },
        videotext: {
            content: 'block+',
            attrs: {
                maxCharsPerBlock: {
                    default: 80,
                },
                maxLinesPerBlock: {
                    default: 2,
                },
            },
            toDOM: node => [
                'videotext',
                {
                    'max-chars-per-block': node.attrs.maxCharsPerBlock,
                    'max-lines-per-block': node.attrs.maxLinesPerBlock,
                },
                0
            ],
            parseDOM: [{
                tag: 'videotext',
                getAttrs: dom => {
                    return {
                        maxCharsPerBlock: parseInt(dom.getAttribute('max-chars-per-block')) || 80,
                        maxLinesPerBlock: parseInt(dom.getAttribute('max-lines-per-block')) || 2,
                    };
                }
            }]
        },
        block: {
            content: 'inline*',
            attrs: {
                start: {
                    default: 0,
                },
                end: {
                    default: null,
                },
            },
            toDOM: node => [
                'block',
                {
                    start: node.attrs.start,
                    end: node.attrs.end,
                },
                0
            ],
            parseDOM: [{
                tag: 'block',
                getAttrs: dom => {
                    return {
                        start: parseFloat(dom.getAttribute('start')),
                        end: dom.getAttribute('end') !== null &&  dom.getAttribute('end') !== '' ? parseFloat(dom.getAttribute('end')) : null,
                    };
                }
            }]
        },
        text: {
            group: 'inline',
        },
        line_break: {
            inline: true,
            group: 'inline',
            selectable: false,
            parseDOM: [{tag: 'br'}],
            toDOM() {
                return ['br'];
            }
        },
    }
});

const cursorBlockIndicatorPluginKey = new PluginKey('cursorBlock');

const cursorBlockIndicatorPlugin = new Plugin({
    key: cursorBlockIndicatorPluginKey,
    props: {
        decorations(state) {
            return this.getState(state).decSet;
        }
    },
    state: {
        init(config, state) {
            return {
                from: null,
                to: null,
                dec: null,
                decSet: DecorationSet.create(state.doc, []),
            };
        },
        apply(tr, value, oldState, newState) {
            if (newState.selection.from !== oldState.selection.from) {
                const from = getBlockNodeBeforePos(newState);
                const to = getBlockNodeAfterPos(newState);
                if (from !== value.from) {
                    const dec = Decoration.node(from, to, {
                        class: 'cursor',
                    });
                    let decSet = value.decSet;
                    decSet = decSet.remove([value.dec]);
                    decSet = decSet.add(newState.doc, [dec]);
                    return {
                        from,
                        to,
                        dec,
                        decSet,
                    };
                }
            }
            return value;
        },
    },
});

const playerPluginKey = new PluginKey('player');

const playerPlugin = new Plugin({

    key: playerPluginKey,

    props: {
        decorations(state) {
            return DecorationSet.create(state.doc, [
                Decoration.node(this.getState(state).from, this.getState(state).to, {
                    class: 'current-time',
                })
            ]);
        }
    },

    state: {

        init() {
            return {
                currentTime: 0,
                from: null,
                to: null,
            };
        },

        apply(tr, value, oldState, newState) {

            if (tr.getMeta('currentTime') === undefined) {
                return value;
            }

            let from = null;
            let to = null;
            const videotextNode = getVideotextNode(newState);
            let lastPos = 0;

            videotextNode.forEach((blockNode, pos) => {
                if (from !== null) {
                    return;
                }
                const time = blockNode.attrs.start;
                if (time > tr.getMeta('currentTime')) {
                    from = getBlockNodeBeforePos(newState, pos);
                    to = getBlockNodeAfterPos(newState, pos);
                }
                lastPos = pos;
            });

            return {
                ...value,
                currentTime: tr.getMeta('currentTime'),
                from,
                to,
            };

        },

    },

    view() {
        return {
            update(view) {
                const pluginState = getPluginState(playerPluginKey, view.state);
                const tc = secondsToTime(pluginState.currentTime);
                document.querySelector('.current-time').innerText = tc;
            }
        }
    }

});

const syncScrollPluginKey = new PluginKey('syncScroll');

const syncScrollPlugin = new Plugin({
    key: syncScrollPluginKey,
    state: {
        init() {
            return localStorage.getItem('syncScroll') === 'true';
        },
        apply(tr, oldSync) {
            let newSync = null;
            if (tr.getMeta('toggleSyncScroll') === true) {
                newSync = !oldSync;
            }
            if (tr.getMeta('setSyncScroll') !== undefined) {
                newSync = tr.getMeta('setSyncScroll');
            }
            if (newSync !== null) {
                if (newSync) {
                    localStorage.setItem('syncScroll', 'true');
                } else {
                    localStorage.setItem('syncScroll', 'false');
                }
                return newSync;
            }
            return oldSync;
        },
    },
    view(view) {
        const syncScrollPluginState = getPluginState(syncScrollPluginKey, view.state);
        setSyncScroll(syncScrollPluginState);
        return {
            update(view, prevState) {
                const syncScrollPluginState = getPluginState(syncScrollPluginKey, view.state);
                view.dom
                    .closest('editor-view-wrapper')
                    .querySelector('.sync-toggle')
                    .checked = syncScrollPluginState;
                const sync = localStorage.getItem('syncScroll') === 'true';
                if (!sync) {
                    return;
                }
                const playerPluginState = getPluginState(playerPluginKey, view.state);
                const playerPluginPrevState = getPluginState(playerPluginKey, prevState);
                if (playerPluginState.currentTime !== playerPluginPrevState.currentTime) {
                    syncScroll(view.state, view.dispatch, view);
                }
            }
        };
    }
});

const localStoragePluginKey = new PluginKey('localStorage');

const localStoragePlugin = new Plugin({
    key: localStoragePluginKey,
    state: {
        init(config, state) {
            this.editorViewId = config.editorViewId;
            if (localStorage.getItem('content' + this.editorViewId)) {
                state.doc = Node.fromJSON(config.schema, JSON.parse(localStorage.getItem('content' + this.editorViewId)));
            }
            if (localStorage.getItem('selection' + this.editorViewId)) {
                state.selection = Selection.fromJSON(state.doc, JSON.parse(localStorage.getItem('selection' + this.editorViewId)));
            }
        },
        apply(tr) {
            localStorage.setItem('content' + this.editorViewId, JSON.stringify(tr.doc.toJSON()));
            localStorage.setItem('selection' + this.editorViewId, JSON.stringify(tr.selection.toJSON()));
        },
    }
});

const debugPluginKey = new PluginKey('debug');

const debugPlugin = new Plugin({
    key: debugPluginKey,
    view() {
        return {
            update(view) {
                // const blockNode = getBlockNode(view.state);
                // console.log('cursor is at:', view.state.selection.from);
                // console.log('node with cursor start:', getBlockNodeStartPos(view.state));
                // console.log('node with cursor end:', getBlockNodeEndPos(view.state));
                // console.log('node with cursor type:', blockNode.type.name);
                // console.log('node with cursor textContent:', blockNode.textContent);
                // const next = getNextBlockNode(view.state);
                // console.log('next:', next ? next.textContent : 'no next');
                // const prev = getPrevBlockNode(view.state);
                // console.log('prev:', prev ? prev.textContent : 'no prev');
            }
        };
    }
});

const blockConstraintsPluginKey = new PluginKey('blockConstraints');

const blockConstraintsPlugin = new Plugin({

    key: blockConstraintsPluginKey,

    view(view) {

        const wrapperEl = getEditorViewWrapperEl(view);
        const maxCharsEl = wrapperEl.querySelector('.max-chars-per-block');
        const maxLinesEl = wrapperEl.querySelector('.max-lines-per-block');

        maxCharsEl.addEventListener('input', e => {
            const max = parseInt(e.target.value);
            setMaxCharsPerBlock(max)(view.state, view.dispatch);
        });

        maxLinesEl.addEventListener('input', e => {
            const max = parseInt(e.target.value);
            setMaxLinesPerBlock(max)(view.state, view.dispatch);
        });

        function update(view) {
            const videotextNode = getVideotextNode(view.state);
            maxCharsEl.value = videotextNode.attrs.maxCharsPerBlock;
            maxLinesEl.value = videotextNode.attrs.maxLinesPerBlock;
        }
        
        update(view);

        return {
            update(view) {
                update(view);
            }
        };
        
    },

});

class BlockView {

    constructor(node, view, getBeforePos) {

        this.view = view;

        this.getBeforePos = getBeforePos;

        this.dom = document.createElement('block');

        this.uiEl = document.querySelector('block-ui.template')
            .cloneNode(true);
        this.uiEl.classList.remove('template');
        this.dom.appendChild(this.uiEl);

        this.startBtnEl = this.uiEl.querySelector('.start');
        this.startBtnEl.addEventListener('click', () => {

            playBlock(this.getStartPos())(view.state, view.dispatch, view);

            const cursorInsideBlock = view.state.selection.anchor >= this.getStartPos()
                && view.state.selection.anchor <= this.getStartPos() + node.content.size;
            if (!cursorInsideBlock) {
                const tr = view.state.tr;
                const selection = TextSelection.create(tr.doc, this.getStartPos());
                tr.setSelection(selection);
                tr.scrollIntoView();
                view.dispatch(tr);
            }

        });

        this.shiftMinusBtnEL = this.uiEl.querySelector('.shift-minus');
        this.shiftMinusBtnEL.addEventListener('click', () => {
            shiftBlock(-1, this.getStartPos())(view.state, view.dispatch, view);
        });

        this.shiftPlusBtnEl = this.uiEl.querySelector('.shift-plus');
        this.shiftPlusBtnEl.addEventListener('click', () => {
            shiftBlock(1, this.getStartPos())(view.state, view.dispatch, view);
        });

        this.insertBtnEl = this.uiEl.querySelector('.insert');
        this.insertBtnEl.addEventListener('click', e => {
            const node = getBlockNode(view.state, this.getStartPos());
            insertBlock(node.attrs.start + 3)(view.state, view.dispatch, view);
        });

        this.splitBtnEl = this.uiEl.querySelector('.split');
        this.splitBtnEl.addEventListener('click', e => {
            splitBlock()(view.state, view.dispatch, view);
        });

        this.deleteBtnEl = this.uiEl.querySelector('.delete');
        this.deleteBtnEl.addEventListener('click', e => {
            deleteBlock(this.getStartPos())(view.state, view.dispatch, view);
        });

        this.charCounterEl = this.uiEl.querySelector('block-char-counter');

        this.contentDOM = document.createElement('block-lines');
        this.dom.appendChild(this.contentDOM);

        this.update(node);

    }

    update(node) {

        this.startBtnEl.innerText = secondsToTime(node.attrs.start);

        this.shiftMinusBtnEL.disabled = !shiftBlock(-1, this.getStartPos())(this.view.state, null);
        this.shiftPlusBtnEl.disabled = !shiftBlock(1, this.getStartPos())(this.view.state, null);
        this.splitBtnEl.disabled = !splitBlock()(this.view.state, null);
        this.deleteBtnEl.disabled = !deleteBlock(this.getStartPos())(this.view.state, null);

        this.updateCharCounter(node);

        return true;

    }

    updateCharCounter(node) {

        const lines = [];
        node.content.forEach(n => {
            if (n.isText) {
                lines.push(n.text.trim());
            }
        });
        if (lines.length === 0) {
            lines.push('');
        }

        const videotextNode = getVideotextNode(this.view.state);
        const maxChars = videotextNode.attrs.maxCharsPerBlock;
        const maxLines = videotextNode.attrs.maxLinesPerBlock;
        const maxCharsPerLine = Math.floor((maxChars * maxLines) / lines.length);

        let numCharsTotal = 0;
        const numCharsPerLine = [];
        let valid = true;
        lines.forEach((l, idx) => {
            if (idx >= maxLines) {
                return;
            }
            const line = l.trim();
            const numChars = line.length;
            numCharsPerLine.push(numChars);
            numCharsTotal += numChars;
            if (numChars > maxCharsPerLine) {
                valid = false;
            }
        });
        if (lines.length > maxLines) {
            valid = false;
        }

        if (valid) {
            this.charCounterEl.classList.remove('alert');
        } else {
            this.charCounterEl.classList.add('alert');
        }

        const pieces = [];
        numCharsPerLine.forEach(num => {
            pieces.push(maxCharsPerLine - num);
        });
        this.charCounterEl.innerText = pieces.join(' | ');

    }

    stopEvent() {
        return true;
    }

    getStartPos() {
        // getBeforePos() = position before node
        // ex: first block: getBeforePos() -> 0
        // so need to + 1 to be actually 'inside' the node
        return this.getBeforePos() + 1;
    }

}

const editorKeymap = {
    ...baseKeymap,
    Enter: insertLineBreak,
};

function insertBlock(start = null) {

    return (state, dispatch, view) => {

        if (start === null) {
            if (mediaLoaded()) {
                start = player.currentTime;
            } else {
                const lastBlockNode = getLastBlockNode(state);
                if (lastBlockNode) {
                    start = lastBlockNode.attrs.start + 3;
                } else {
                    start = 0;
                }
            }
        }

        if (blockWithStartExists(state, start)) {
            return false;
        }

        if (dispatch) {

            let pos = getBlockNodeBeforePosForTime(state, start);
            if (!Number.isInteger(pos)) {
                pos = state.doc.content.size;
            }
            const blockNode = schema.nodes.block.createAndFill({
                start,
            });

            const tr = state.tr;

            tr.insert(pos, blockNode);

            const selection = TextSelection.create(tr.doc, pos + 1);
            tr.setSelection(selection);
            tr.scrollIntoView();

            dispatch(tr);

            view.focus();

        }

        // TODO: stupid hack to prevent remembering
        start = null;
        
        return true;

    }

}

function splitBlock(pos = null, start = null) {

    return (state, dispatch, view) => {

        if (pos === null) {
            pos = state.selection.head;
        }

        const blockNode = getBlockNode(state, pos);

        // TODO: remove this, causes an error when loading
        if (!blockNode) {
            return false;
        }

        if (blockNode.content.size < 2) {
            return false;
        }

        if (mediaLoaded()) {
            start = player.currentTime;
        } else {
            const duration = getBlockDuration(state, pos);
            start = blockNode.attrs.start + duration / 2;
        }

        if (dispatch) {
            
            const tr = state.tr;

            tr.setNodeMarkup(getBlockNodeBeforePos(state, pos), null, {
                ...blockNode.attrs,
                end: null,
            });

            tr.split(pos, 1, [{
                type: schema.nodes.block,
                attrs: {
                    start: start,
                }
            }]);

            const selection = TextSelection.create(tr.doc, pos + 1);
            tr.setSelection(selection);
            tr.scrollIntoView();

            dispatch(tr);

            view.focus();

            // TODO: stupid hack to prevent remembering
            start = null;
            pos = null;
            
        }
        
        return true;

    };

}

function insertLineBreak(state, dispatch) {
    if (dispatch) {
        dispatch(state.tr.replaceSelectionWith(schema.nodes.line_break.create()));
    }
    return true;
}

function playBlock(pos = null) {

    return (state, dispatch, view) => {

        if (!mediaLoaded()) {
            return false;
        }

        if (dispatch) {

            const blockNode = getBlockNode(state, pos);
            player.currentTime = blockNode.attrs.start;
            player.play();

            const tr = state.tr;
            const selection = TextSelection.create(state.doc, getBlockNodeStartPos(state, pos));
            tr.setSelection(selection);
            tr.scrollIntoView();
            dispatch(tr);

            view.focus();
            
        }

        return true;

    }

}

function shiftBlock(delta, pos = null) {

    return (state, dispatch, view) => {

        const blockNode = getBlockNode(state, pos);

        let newStart = blockNode.attrs.start + delta;

        if (newStart < 0) {
            newStart = 0;
        } else if (mediaLoaded() && (newStart > player.duration)) {
            newStart = player.duration;
        }

        if (delta < 0) {
            if (blockNode.attrs.start === 0) {
                return false;
            }
            const prevBlockNode = getPrevBlockNode(state, pos);
            if (prevBlockNode && newStart <= prevBlockNode.attrs.start) {
                return false;
            }
        }

        if (delta > 0) {
            if (mediaLoaded() && (blockNode.attrs.start >= player.duration)) {
                return false;
            }
            const nextBlockNode = getNextBlockNode(state, pos);
            if (nextBlockNode && newStart >= nextBlockNode.attrs.start) {
                return false;
            }
        }

        if (dispatch) {

            dispatch(state.tr.setNodeMarkup(getBlockNodeBeforePos(state, pos), null, {
                ...blockNode.attrs,
                start: newStart,
            }));

            if (mediaLoaded()) {
                player.currentTime = newStart;
                player.play();
            }

            view.focus();

        }

        return true;

    }
    
}

function deleteBlock(pos) {

    return (state, dispatch, view) => {
        
        if (getNumBlocks(state) <= 1) {
            return false;
        }

        if (dispatch) {
            if (mediaLoaded()) {
                player.pause();
            }
            const tr = state.tr.delete(
                getBlockNodeBeforePos(state, pos),
                getBlockNodeAfterPos(state, pos)
            );
            dispatch(tr);
            view.focus();
        }

        return true;

    };

}

function setSyncScroll(sync) {

    return (state, dispatch) => {
        if (dispatch) {
            const tr = state.tr;
            tr.setMeta('setSyncScroll', sync);
            dispatch(tr);
        }
        return true;
    };

}

function toggleSyncScroll(state, dispatch) {

    if (dispatch) {
        const tr = state.tr;
        tr.setMeta('toggleSyncScroll', true);
        dispatch(tr);
    }
    return true;

}

function syncScroll(state, dispatch, view) {
    const currentTimeBlockEl = view.dom.querySelector('.current-time');
    if (!currentTimeBlockEl) {
        return;
    }
    currentTimeBlockEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });
    return true;
}

function setMaxCharsPerBlock(max) {
    return (state, dispatch) => {
        if (dispatch) {
            const tr = state.tr;
            tr.setNodeMarkup(getVideotextBeforeStartPos(state), null, {
                ...getVideotextNode(state).attrs,
                maxCharsPerBlock: max,
            });
            dispatch(tr);
        }
        return true;
    };
}

function setMaxLinesPerBlock(max) {
    return (state, dispatch) => {
        if (dispatch) {
            const tr = state.tr;
            tr.setNodeMarkup(getVideotextBeforeStartPos(state), null, {
                ...getVideotextNode(state).attrs,
                maxLinesPerBlock: max,
            });
            dispatch(tr);
        }
        return true;
    };
}

function applyFallbackBlockNodePos(state, pos = null) {
    if (pos === null) {
        pos = state.selection.head;
    }
    if (pos === 0) {
        pos = getFirstBlockNodeStartPos(state);
    }
    return pos;
}

function getVideotextNode(state) {
    return state.doc.child(0);
}

function getVideotextBeforeStartPos(state) {
    return 0;
}

function getBlockNode(state, pos = null) {
    pos = applyFallbackBlockNodePos(state, pos);
    const $pos = state.doc.resolve(pos);
    return $pos.node(2);
}

function getNextBlockNode(state, pos = null) {
    pos = getBlockNodeAfterPos(state, pos);
    return state.doc.resolve(pos).nodeAfter;
}

function getPrevBlockNode(state, pos = null) {
    pos = getBlockNodeBeforePos(state, pos);
    return state.doc.resolve(pos).nodeBefore;
}

function getBlockDuration(state, pos) {
    pos = getBlockNodeStartPos(state, pos);
    const thisBlock = getBlockNode(state, pos);
    const nextBlock = getNextBlockNode(state, pos);
    let end;
    if (nextBlock) {
        end = nextBlock.attrs.start;
    } else if(mediaLoaded()) {
        end = player.duration;
    } else {
        end = thisBlock.attrs.start + 3;
    }
    return end - thisBlock.attrs.start;
}

function getBlockNodeStartPos(state, pos = null) {
    pos = applyFallbackBlockNodePos(state, pos);
    const $pos = state.doc.resolve(pos);
    return $pos.start(2);
}

function getBlockNodeEndPos(state, pos = null) {
    pos = applyFallbackBlockNodePos(state, pos);
    const $pos = state.doc.resolve(pos);
    return $pos.end(2);
}

function getFirstBlockNodeStartPos(state) {
    return 3;
}

function getBlockNodeBeforePos(state, pos = null) {
    pos = applyFallbackBlockNodePos(state, pos);
    const $pos = state.doc.resolve(pos);
    return $pos.before(2);
}

function getBlockNodeAfterPos(state, pos = null) {
    pos = applyFallbackBlockNodePos(state, pos);
    const $pos = state.doc.resolve(pos);
    return $pos.after(2);
}

function getBlockNodeBeforePosForTime(state, time) {
    let posForTime = null;
    const videotextNode = getVideotextNode(state);
    videotextNode.forEach((blockNode, pos) => {
        if (posForTime !== null) {
            return;
        }
        if (blockNode.attrs.start > time) {
            posForTime = getBlockNodeBeforePos(state, pos);
        }
    });
    return posForTime;
}

function blockWithStartExists(state, start) {
    let exists = false;
    const videotextNode = getVideotextNode(state);
    videotextNode.forEach(blockNode => {
        if (exists) {
            return;
        }
        const startHere = blockNode.attrs.start;
        const diff = Math.abs(start - startHere);
        if (diff <= 1) {
            exists = true;
        }
    });
    return exists;
}

function getLastBlockNode(state) {
    return state.doc.lastChild;
}

function getNumBlocks(state) {
    return getVideotextNode(state).childCount;
}

function getEmptyDoc() {
    const block = schema.node('block', {start: 0}, [
        schema.text('Type here'),
    ]);
    const videotext = schema.node('videotext', null, [block]);
    return schema.node('doc', null, videotext);
}

function srtStringToDoc(strString) {

    const srtBlockNodes = parseSync(strString);
    const blockNodes = [];

    srtBlockNodes.forEach(srtBlockNode => {

        const blockNodeContent = [];

        const lines = srtBlockNode.data.text.trim().split(/\r?\n/);
        lines.forEach((line, idx) => {
            blockNodeContent.push(schema.text(line ? line : ' '));
            if (idx !== lines.length - 1) {
                blockNodeContent.push(schema.node('line_break'));
            }
        });
        if (blockNodeContent.length === 0) {
            blockNodeContent.push(schema.text(' '));
        }

        const blockNode = schema.node(
            'block',
            {
                start: srtBlockNode.data.start / 1000,
                end: srtBlockNode.data.end / 1000,
            },
            blockNodeContent
        );
        blockNodes.push(blockNode);

    });
    
    const videotextNode = schema.node('videotext', null, blockNodes);
    return schema.node('doc', null, [videotextNode]);

}

function docToStrString(doc) {

    const srtNodes = [];

    for (let i = 0; i < doc.childCount; i++) {

        const isLast = i === doc.childCount - 1;
        const blockNode = doc.child(i);
        const nextBlockNode = !isLast ? doc.child(i + 1) : null;

        const start = blockNode.attrs.start;

        let end;
        if (blockNode.attrs.end) {
            end = blockNode.attrs.end;
        } else if(nextBlockNode) {
            end = nextBlockNode.attrs.end;
        } else {
            end = player.duration;
        }
        
        let text = '';
        for (let j = 0; j < blockNode.childCount; j++) {
            const blockChild = blockNode.child(j);
            if (blockChild.type.name === 'text') {
                text += blockChild.text;
            } else if (blockChild.type.name === 'line_break') {
                text += '\n';
            }
        }

        const srtNode = {
            type: 'cue',
            data: {
                start: start * 1000,
                end: end * 1000,
                text: text,
            }
        };
        srtNodes.push(srtNode);

    }

    return stringifySync(srtNodes, { format: 'SRT' });

}

function replaceEditorDoc(editorView, doc) {
    const tr = editorView.state.tr;
    tr.replaceRangeWith(0, editorView.state.doc.content.size, doc);
    editorView.dispatch(tr);
    editorView.focus();
}

function getPluginState(pluginKey, state) {
    return pluginKey.get(state).getState(state);
}

function initPlayer() {

    player.on('timeupdate', e => {
        const currentTime = e.detail.plyr.currentTime;
        editorViews.forEach(view => {
            view.dispatch(view.state.tr.setMeta('currentTime', currentTime));
        });
    });

    if (localStorage.getItem('mediaUrl')) {
        player.once('ready', () => {
            setPlayerSource(localStorage.getItem('mediaUrl'));
        });
    }

}

function setPlayerSource(url) {
    
    let provider;
    if (url.includes('vimeo')) {
        provider = 'vimeo';
    }
    if (url.includes('youtube')) {
        provider = 'youtube';
    }

    player.source = {
        type: 'video',
        sources: [
            {
                src: url,
                provider: provider,
            },
        ],
    };

    localStorage.setItem('mediaUrl', url);

    document.querySelector('.media-url').innerHTML = url;
    document.querySelector('.media-url').href = url;

    // fix YouTube video start muted
    player.once('playing', () => {
        const wasMuted = player.muted;
        player.muted = false;
        player.muted = true;
        player.muted = wasMuted;
    });

    // disable vimeo subs
    if (player.embed) {
        player.once('play', () => {
            player.embed.disableTextTrack();
        });
    }

}

function mediaLoaded() {
    return player && player.ready;
}

function secondsToTime(s) {
    const date = new Date(0);
    date.setSeconds(s);
    return date.toISOString().substr(11, 8);
}

function timeToSeconds(t) {
    const p = t.split(':');
    let s = 0;
    let m = 1;
    while (p.length > 0) {
        s += m * parseInt(p.pop(), 10);
        m *= 60;
    }
    return s;
}

function trans(s) {
    return s;
}

const editorViews = [];

function getEditorViewByWrapperEl(wrapperEl) {
    const el = wrapperEl.querySelector('.ProseMirror');
    let view = null;
    editorViews.forEach(viewHere => {
        if (viewHere.dom.isEqualNode(el)) {
            view = viewHere;
        }
    });
    return view;
}

function getEditorViewWrapperEl(view) {
    return view.dom.closest('editor-view-wrapper');
}

function getActiveEditorView() {
    return editorViews[0];
}

function buildEditor() {
    
    const doc = getEmptyDoc();

    const viewEl = document.querySelector('editor-view-wrapper.template')
        .cloneNode(true);
    viewEl.classList.remove('template');
    document.querySelector('.player-and-subtitles').appendChild(viewEl);

    const state = EditorState.create({
        doc,
        schema,
        plugins: [
            history(),
            keymap({"Mod-z": undo, "Mod-y": redo}),
            keymap(editorKeymap),
            cursorBlockIndicatorPlugin,
            playerPlugin,
            localStoragePlugin,
            syncScrollPlugin,
            blockConstraintsPlugin,
            debugPlugin,
        ],
        editorViewId: editorViews.length,
    });

    const view = new EditorView(viewEl, {
        state,
        nodeViews: {
            block(node, view, getBeforePos) {
                return new BlockView(node, view, getBeforePos);
            }
        },
    });

    if (editorViews.length === 0) {
        view.focus();
        applyDevTools(view);
    }

    editorViews.push(view);

}

// sync once
document.addEventListener('click', e => {
    if (!e.target.classList.contains('sync-once')) {
        return;
    }
    const wrapperEl = e.target.closest('editor-view-wrapper');
    const editorView = getEditorViewByWrapperEl(wrapperEl);
    syncScroll(editorView.state, editorView.dispatch, editorView);
});

// sync toggle
document.addEventListener('click', e => {
    if (!e.target.classList.contains('sync-toggle')) {
        return;
    }
    const wrapperEl = e.target.closest('editor-view-wrapper');
    const editorView = getEditorViewByWrapperEl(wrapperEl);
    setSyncScroll(e.target.checked)(editorView.state, editorView.dispatch, editorView);
});

// new subtitle
document.addEventListener('click', e => {

    if (!e.target.classList.contains('new-subtitle')) {
        return;
    }

    const doc = getEmptyDoc();
    const wrapperEl = e.target.closest('editor-view-wrapper');
    const editorView = getEditorViewByWrapperEl(wrapperEl);
    replaceEditorDoc(editorView, doc);

    const tr = editorView.state.tr;
    tr.setSelection(TextSelection.create(tr.doc, 1, tr.doc.content.size - 1));
    tr.scrollIntoView();

});

// open subtitle
document.addEventListener('change', e => {

    if (!e.target.classList.contains('open-subtitle')) {
        return;
    }

    const file = e.target.files[0];
    if (file.type && file.type !== 'application/x-subrip') {
        return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => {

        const srtString = reader.result.toString();
        const doc = srtStringToDoc(srtString);
        const wrapperEl = e.target.closest('editor-view-wrapper');
        const editorView = getEditorViewByWrapperEl(wrapperEl);
        replaceEditorDoc(editorView, doc);

        const tr = editorView.state.tr;
        tr.setSelection(TextSelection.create(tr.doc, 1));
        tr.scrollIntoView();
        editorView.dispatch(tr);
        editorView.focus();

    });
    reader.readAsText(file);

});

// save subtitle
document.addEventListener('click', e => {
    
    if (!e.target.classList.contains('save-subtitle')) {
        return;
    }

    const wrapperEl = e.target.closest('editor-view-wrapper');
    const editorView = getEditorViewByWrapperEl(wrapperEl);
    const srtString = docToStrString(editorView.state.doc);

    const filename = 'subtitle.srt';
    const blob = new Blob([srtString], {
        type : 'application/x-subrip',
    });
    const blobUrl = URL.createObjectURL(blob);
    const aEl = document.createElement('a');
    aEl.href = blobUrl;
    aEl.download = filename;
    document.body.appendChild(aEl);

    aEl.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );
    
    document.body.removeChild(aEl);

});

// load online media
window.addEventListener('click', e => {
    if (!e.target.classList.contains('load-media')) {
        return;
    }
    const url = window.prompt('Media URL', 'https://vimeo.com/302443752');
    if (url) {
        setPlayerSource(url);
    }
});

// load local media
document.addEventListener('change', e => {

    if (!e.target.classList.contains('load-media-local')) {
        return;
    }

    const file = e.target.files[0];
    if (file.type && false) {
        return;
    }

    const fileURL = URL.createObjectURL(file);
    setPlayerSource(fileURL);

});

// // initial overlay
// document.addEventListener('click', e => {
//     if (e.target.closest('.init-overlay')) {
//         document.querySelector('.init-overlay').remove();
//     }
// });
// document.addEventListener('keypress', e => {
//     if (e.target.closest('.init-overlay')) {
//         document.querySelector('.init-overlay').remove();
//     }
// });

const player = new Plyr(document.querySelector('.player'), {
    iconUrl: require('./node_modules/plyr/dist/plyr.svg'),
    hideControls: false,
    invertTime: false,
});
window.player = player;

function initKbd() {

    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key.toLowerCase() === 'j') {
            player.rewind(1);
            e.preventDefault();
        }
    });
    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key.toLowerCase() === 'k') {
            player.togglePlay();
            e.preventDefault();
        }
    });
    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key.toLowerCase() === 'l') {
            player.forward(10);
            e.preventDefault();
        }
    });
    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.altKey) {
            if (e.key === 'ArrowUp') {
                player.speed += 0.25;
                e.preventDefault();
            } else if(e.key === 'ArrowDown') {
                player.speed -= 0.25;
                e.preventDefault();
            }
        }
    });

    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key.toLowerCase() === 'p') {
            const view = getActiveEditorView();
            playBlock()(view.state, view.dispatch, view);
            e.preventDefault();
        }
    });

    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key === 'Enter' && !e.shiftKey) {
            const view = getActiveEditorView();
            insertBlock()(view.state, view.dispatch, view);
            e.preventDefault();
        }
    });
    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key === 'Enter' && e.shiftKey) {
            const view = getActiveEditorView();
            splitBlock()(view.state, view.dispatch, view);
            e.preventDefault();
        }
    });
    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.code === 'BracketLeft') {
            const view = getActiveEditorView();
            shiftBlock(-1)(view.state, view.dispatch, view);
            e.preventDefault();
        }
    });
    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.code === 'BracketRight') {
            const view = getActiveEditorView();
            shiftBlock(1)(view.state, view.dispatch, view);
            e.preventDefault();
        }
    });
    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key === 'Delete') {
            const view = getActiveEditorView();
            deleteBlock()(view.state, view.dispatch, view);
            e.preventDefault();
        }
    });

    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.altKey && e.key === ':') {
            const view = getActiveEditorView();
            toggleSyncScroll(view.state, view.dispatch);
            e.preventDefault();
        }
    });
    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key === ':') {
            const view = getActiveEditorView();
            syncScroll(view.state, view.dispatch, view);
            e.preventDefault();
        }
    });

}

function init() {
    buildEditor();
    initPlayer();
    initKbd();
}

onlyOneTab(async () => {
    await init();
    document.querySelector('.already-open-alert').style.display = 'none';
});
document.querySelector('.already-open-alert').style.display = '';
